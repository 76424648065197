
const PrivacyPolicy = () => {
  return (
    <div className="text-content">
      <h1>Privacy Policy</h1>
      <p>We take data protection and privacy very seriously at Beem. This Privacy Policy outlines how we collect and use your Personal Data. Below is an overview of the key points of our privacy policy and practices:&nbsp;</p>
      <ul>
        <li>We do not re-sell Personal Data.</li>
        <li>We take care to encrypt Private Sensitive Personal Data (defined below) like journal entries or reflections (only you have access to that content).</li>
        <li>​​Any App Analytics Data that we do collect &ndash; in the spirit of improving or personalizing the product, messaging, and content &ndash; is studied only at an aggregate level across the Beem community (e.g., which sessions are most popular), unless necessary for specific customer support or de-bugging.</li>
        <li>We do not sell, rent, or otherwise provide users&rsquo; Personal Data to any 3rd party data brokers.</li>
        <li>As is customary for web-based services, we partner with Service Providers (e.g., payment processors, database providers) to support our App and Analytics Partners to study how best to improve our App (e.g., which sessions are most popular). Personal Data shared with these providers is encrypted and protected both in transit and at rest.</li>
        <li>We do not share any Private Sensitive Personal Data with any Advertising Partners.&nbsp;</li>
        <li>We do not knowingly collect or solicit the data of individuals under the age of 13 without obtaining COPPA-compliant consent from a parent or guardian, and we do not knowingly share information about individuals under the age of 13 with Advertising Partners.</li>
        <li>You may, subject to the terms of this Privacy Policy, delete your full account and all data associated with it at any point.</li>
      </ul>
      <p>At any time you may opt out of cookies and other tracking or exercise your other rights under the General Data Protection Regulation. Please read on to the Full Privacy Policy for more details.</p>
      <p>Your use of our Services is subject to our&nbsp;Terms of Service, which incorporates this Privacy Policy. Any terms we use in this Privacy Policy without defining them have the definitions given to them in the Terms of Service. If we make any changes to the way we collect or use your Personal Data we will send you a notice of those changes in accordance with the section titled&nbsp;<u>&ldquo;Changes to this Privacy Policy&rdquo;</u>&nbsp;below.&nbsp;</p>
      <p><strong>By creating an account, accessing our Services, or opting into communications from us, you acknowledge that you accept the practices and policies outlined below, and you consent that we will collect, use and share your information as described in this Privacy Policy.</strong>&nbsp;If you are under 18, please be sure to read this Privacy Policy with a parent or guardian.</p>
      <p><strong>Table of Contents</strong></p>
      <ol>
        <li><strong><u>What this Privacy Policy Covers</u></strong></li>
        <li><strong><u>Personal Data</u></strong>
          <ol>
            <li><em><u>Categories of Personal Data We Collect</u></em></li>
            <li><em><u>Categories of Sources of Personal Data</u></em></li>
            <li><em><u>Our Commercial or Business Purposes for Collecting Personal Data</u></em></li>
          </ol>
        </li>
        <li><strong><u>Data Sharing</u></strong></li>
        <li><strong><u>Data Security and Retention</u></strong></li>
        <li><strong><u>Personal Data of Children</u></strong></li>
        <li><strong><u>California Resident Rights</u></strong></li>
        <li><strong><u>Other State Law Privacy Rights</u></strong></li>
        <li><strong><u>European Union Data Subject Rights</u></strong></li>
        <li><strong><u>Brazil Data Subject Rights</u></strong></li>
        <li><strong><u>Changes to this Privacy Policy</u></strong></li>
        <li><strong><u>Contact Information</u></strong></li>
      </ol>
      <p><strong>1. What this Privacy Policy Covers</strong></p>
      <p>This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. &ldquo;Personal Data&rdquo; means any information that identifies or relates to a particular individual and also includes information referred to as &ldquo;personally identifiable information&rdquo; or &ldquo;personal information&rdquo; under applicable data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we don&rsquo;t own or control or people we don&rsquo;t manage.</p>
      <p><strong>2. Personal Data</strong></p>
      <figure>
        <table>
          <tbody>
            <tr>
              <td><strong>Category of Personal Data:</strong></td>
              <td><strong>Why We Collect This:</strong></td>
              <td><strong>Examples of Personal Data We Collect:</strong></td>
              <td><strong>Categories of Third Parties With Whom We Share this Personal Data:</strong></td>
            </tr>
            <tr>
              <td><strong>Profile or Contact Data</strong></td>
              <td>Collected for the purpose of creating and managing user accounts and shared with Service Providers and Analytics Partners (each as defined below) to deliver and improve the Services.&nbsp;</td>
              <td>&ndash; Name&ndash; Email&ndash; Phone number</td>
              <td>&ndash; Service Providers&ndash; Analytics Partners&ndash; Parties You Authorize, Access or Authenticate</td>
            </tr>
            <tr>
              <td><strong>Private Sensitive Personal Data</strong></td>
              <td>Collected to provide you with the Services and shared with Service Providers to deliver our Services (e.g., database provider); however, this Personal Data is encrypted with industry standard encryption.&nbsp;</td>
              <td>&ndash; Passwords</td>
              <td>&ndash; Service Providers&ndash; Parties You Authorize, Access or Authenticate</td>
            </tr>
            <tr>
              <td><strong>App Analytics&nbsp;</strong></td>
              <td>Collected for studying, at the aggregate level (unless necessary for specific customer support or de-bugging), and personalizing or improving the messaging or Services (e.g. which sessions or functionality are most popular) and shared with Service Providers and Analytics Partners to deliver and improve our Services.&nbsp;</td>
              <td>&ndash; Specific session or listening activity&ndash; Engagement with App functionality or posts to in-App groups</td>
              <td>&ndash; Service Providers&ndash; Analytics Partners&ndash; Parties You Authorize, Access or Authenticate</td>
            </tr>
            <tr>
              <td><strong>Advertising Data</strong></td>
              <td>Collected and shared with our Service Providers, Analytics Partners, and Advertising Partners to help us market our services, for instance by measuring the effectiveness of our advertising campaigns.</td>
              <td>&ndash; Whether the user began using the App (i.e., if the App was installed, an account created, first session started, or trial started)&nbsp;&ndash; Hashed email address and name&ndash; Device operating system&ndash; Device ID if granted by user (opt-in for iOS, opt-out for Android)</td>
              <td>&ndash; Service Providers&ndash; Analytics Partners&ndash; Advertising Partners</td>
            </tr>
            <tr>
              <td><strong>Device/IP Data</strong></td>
              <td>Collected automatically to assist with analytics and services like customer support and shared with Service Providers and Analytics Partners in order to deliver and improve our Services.</td>
              <td>&ndash; IP address&ndash; Device ID&ndash; Domain server&ndash; Device operating system&ndash; App version</td>
              <td>&ndash; Service Providers&ndash; Analytics Partners</td>
            </tr>
            <tr>
              <td><strong>Cookie Data &amp; Web Analytics</strong></td>
              <td>We have cookies on our Site (not on the App), including those belonging to our Advertising Partners, that collect and share Personal Data with our Service Providers and Analytics Partners. User cookie preferences can be managed using our&nbsp;Cookie Manager. Targeting Cookies, as defined in this Privacy Policy, are not present once the user enters the App such that Targeting Cookies do not capture any listening activity, journal entry data, or other App Analytics data.</td>
              <td>&ndash; Web page interactions&ndash; Referring webpage/source through which you accessed the Services&ndash; Non-identifiable request IDs&ndash; IP address&ndash; Statistics associated with the interaction between device or browser and the Services</td>
              <td>&ndash; Service Providers&ndash; Advertising Partners&ndash; Analytics Partners</td>
            </tr>
            <tr>
              <td><strong>Consumer Demographic Data</strong></td>
              <td>We collect demographic data for the purpose of collecting Parental Consent and share with Service Providers and Analytics Partners in order to deliver and improve our Services.</td>
              <td>&ndash; Age / date of birth (only collected as part of the Parental Consent process)&ndash; Gender (inferred based on first name)&nbsp;</td>
              <td>&ndash; Service Providers&ndash; Analytics Partners</td>
            </tr>
            <tr>
              <td><strong>Geolocation Data</strong></td>
              <td>We do not collect specific location information, but certain Service Providers (e.g., customer support tools) and Analytics Partners infer it based on IP address.&nbsp;</td>
              <td>&ndash; IP-address-based location information</td>
              <td>&ndash; Service Providers&ndash; Analytics Partners</td>
            </tr>
            <tr>
              <td><strong>Health Data</strong></td>
              <td>Shared with third-party services like Apple Health at user direction.</td>
              <td>&ndash; Activity time</td>
              <td>&ndash; Service Providers&ndash; Parties You Authorize, Access, or Authenticate</td>
            </tr>
            <tr>
              <td><strong>Other Identifying Information that You Voluntarily Choose to Provide</strong></td>
              <td>Optional information, like opt-in survey data, that we collect to better understand our Services and share with Service Providers and Analytics Partners in order to deliver our Services.</td>
              <td>&ndash; Identifying information in optional surveys, emails, or other communications that you send or otherwise provide to us</td>
              <td>&ndash; Service Providers&ndash; Analytics Partners</td>
            </tr>
          </tbody>
        </table>
      </figure>
      <p><strong>Categories of Personal Data We Collect</strong></p>
      <p>This section details the categories of Personal Data that we collect and have collected over the past 12 months. See the&nbsp;<u>&ldquo;Data Sharing&rdquo;</u>&nbsp;section further below for more information about our sharing practices.</p>
      <p>The California Consumer Privacy Act (&ldquo;CCPA&rdquo;) also requires us to describe our practices over the past twelve months prior to the date of this Privacy Policy.&nbsp; Our practices in the twelve months prior to that date are reflected in the table above, but additionally, we disclosed some limited App Analytics data (which, for clarity, did&nbsp;<em>not</em>&nbsp;include any information about session titles users listened to, or any Private Sensitive Personal Data) to certain Advertising Partners.&nbsp; We have discontinued that practice.</p>
      <p><strong>Categories of Sources of Personal Data</strong></p>
      <p>We collect Personal Data about you from the following categories of sources:</p>
      <ul>
        <li><strong>From You</strong>
          <ul>
            <li><u>When you provide such information directly to us.</u>
              <ul>
                <li>When you create an account or use our interactive tools and Services.</li>
                <li>When you voluntarily provide information in free-form text boxes through the Services or through responses to forms, surveys, sweepstakes or contests.</li>
                <li>When you send us an email or otherwise contact us. &nbsp;</li>
              </ul>
            </li>
            <li><u>When you use the Services and such information is collected automatically.</u>
              <ul>
                <li>Using Cookies.</li>
                <li>If you download our mobile application or use a location-enabled browser, we may receive information about your location and mobile device, as applicable.</li>
                <li>If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li><strong>From Third Parties</strong>
          <ul>
            <li><u>Vendors</u>&nbsp;and Publicly Available Data
              <ul>
                <li>We may use Analytics Partners to analyze how you interact and engage with the Services.</li>
                <li>We use vendors (e.g. ZoomInfo) and information available on public websites to obtain contact information to generate leads for our Enterprise Account sales.</li>
                <li>One of the vendors we work with (i.e. Baremetrics &ndash; our revenue analytics platform) automatically links to publicly available social media profiles where available. Once we discovered this we opted out of this functionality.</li>
              </ul>
            </li>
            <li><u>Advertising Partners</u>
              <ul>
                <li>We receive information about you from some of our vendors who assist us with marketing or promotional services related to how you interact with our websites, applications, products, Services, advertisements, or communications.</li>
              </ul>
            </li>
            <li><u>Third-Party Sign-in&nbsp;</u>
              <ul>
                <li>If you provide your account credentials to us or otherwise sign in to the Services through a third-party site or service, like Apple or Google, some content and/or information in those accounts may be transmitted into your account with us in accordance with the authorization process of that site and/or your device settings</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p><strong>Our Commercial or Business Purposes for Collecting Personal Data</strong></p>
      <ul>
        <li><strong>Providing, Customizing and Improving the Services</strong>
          <ul>
            <li>Creating and managing your account or other user profiles.</li>
            <li>Providing you with the products, services or information you request.</li>
            <li>Meeting or fulfilling the reason you provided the information to us.</li>
            <li>Providing support and assistance for the Services.</li>
            <li>Improving the Services, including testing, research, internal analytics and product development.</li>
            <li>Personalizing the Services, website content and communications based on your preferences.</li>
            <li>Doing fraud protection, security and debugging.</li>
            <li>Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws, such as the California Consumer Privacy Act (the &ldquo;CCPA&rdquo;).</li>
          </ul>
        </li>
        <li><strong>Marketing the Services</strong>
          <ul>
            <li>Marketing Services.</li>
            <li>Showing you advertisements, including interest-based or online behavioral advertising. Note that we do not knowingly use or disclose Personal Data of any users that we know to be a Child under the Age of Consent (defined in the&nbsp;<u>&ldquo;Personal Data of Children&rdquo;</u>&nbsp;section below) for behavioral advertising</li>
            <li>Facilitating sweepstakes/contests or other promotions.</li>
          </ul>
        </li>
        <li><strong>Corresponding with You</strong>
          <ul>
            <li>Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you information about Beem or the Services.</li>
            <li>Sending emails and other communications according to your preferences or that display content that we think will interest you.</li>
          </ul>
        </li>
        <li><strong>Meeting Legal Requirements and Enforcing Legal Terms</strong>
          <ul>
            <li>Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities.</li>
            <li>Protecting the rights, property or safety of Beem users, Beem or another party.</li>
            <li>Enforcing agreements.</li>
            <li>Responding to claims that any posting or other content violates third-party rights.</li>
            <li>Resolving disputes.</li>
          </ul>
        </li>
      </ul>
      <p>We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated or incompatible purposes without providing you notice in accordance with the&nbsp;<u>&ldquo;Changes to this Privacy Policy&rdquo;</u>&nbsp;section below.</p>
      <p><strong>3. Data Sharing</strong></p>
      <p>We disclose your Personal Data to the categories of service providers and other parties listed in this section. For more information, please refer to the state-specific sections below.</p>
      <ul>
        <li><strong>Service Providers.</strong>&nbsp;These parties help us provide the Services or perform business functions on our behalf. They include:
          <ul>
            <li>Hosting, technology, and communication providers.</li>
            <li>Support and customer service vendors.</li>
            <li>Product fulfillment and delivery providers.</li>
          </ul>
        </li>
        <li><strong>Advertising Partners.&nbsp;</strong>These parties help us market our services and provide you with other offers that may be of interest to you. They include:
          <ul>
            <li>Ad networks.</li>
            <li>Marketing providers.</li>
          </ul>
        </li>
        <li><strong>Analytics Partners.&nbsp;</strong>These parties provide analytics on web traffic or usage of the Services. They include:
          <ul>
            <li>Companies that track how users interact with the Services.</li>
            <li>Companies that track how users found or were referred to the Services.&nbsp;</li>
          </ul>
        </li>
        <li><strong>Parties You Authorize, Access or Authenticate</strong>
          <ul>
            <li>Third parties you access through the services.</li>
            <li>Other users.</li>
          </ul>
        </li>
      </ul>
      <p><strong>Legal Obligations</strong></p>
      <p>We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth under &ldquo;Meeting Legal Requirements and Enforcing Legal Terms&rdquo; in the&nbsp;<u>&ldquo;Our Commercial or Business Purposes for Collecting Personal Data&rdquo;</u>&nbsp;section above.&nbsp;</p>
      <p><strong>Business Transfers</strong></p>
      <p>All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before (most likely via email) your information becomes subject to different privacy or security policies and practices.</p>
      <p><strong>Data that is Not Personal Data</strong></p>
      <p>We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and share it with third parties for our lawful business purposes, including to analyze, build and improve the Services and promote our business, provided that we will not share such data in a manner that could identify you. &nbsp;</p>
      <p><strong>4. Data Security and Retention</strong></p>
      <p>We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational&nbsp; and administrative security measures based on the type of Personal Data and how we are processing that data.&nbsp;<strong>We use industry-standard encryption to protect your data, and we further encrypt Private Sensitive Personal Data in a manner designed to ensure only you and those you choose to share it with can view it in unencrypted form.&nbsp;</strong>We also ask for your help to protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the internet or storing data is completely secure.</p>
      <p>We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you with our Services. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal or business obligations, resolve disputes or collect fees owed, or is otherwise required by applicable law, rule or regulation. We may also retain information in an anonymous, de-identified, aggregated form such that information would not identify you personally.</p>
      <p><strong>5. Personal Data of Children</strong></p>
      <p>The Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13 years of age. Moreover, the EU General Data Protection Regulation (the &ldquo;GDPR&rdquo;) requires that data controllers obtain consent from the holders of parental responsibility over children who are under 16 years of age before such controllers knowingly collect and process Personal Data from such children.&nbsp;</p>
      <p>We do not knowingly collect or solicit personally identifiable information from a child under 13 (if such child is located in the United States) or under 16 (if such child is located in the European Union or United Kingdom) (in each case, a &ldquo;Child under the Age of Consent&rdquo;) without obtaining verifiable consent from that child&rsquo;s parent or guardian (&ldquo;Parental Consent&rdquo;), except for the limited amount of personally identifiable information we need to collect in order to obtain Parental Consent (&ldquo;Required Information&rdquo;). Until we have received Parental Consent, we will only use Required Information for the purpose of obtaining Parental Consent. If you are a Child under the Age of Consent, please do not attempt to send any Personal Data about yourself to us before we obtain Parental Consent, except for the Required Information in the context of the Parental Consent process. If you believe that a Child under the Age of Consent has provided us with Personal Data (beyond the Required Information) without our obtaining Parental Consent, please contact us at&nbsp;<u>info@beem.earth</u>. We do not condition participation in our Services on disclosure of more Personal Data from a Child under the Age of Consent than is reasonably necessary for that participation, and we do not retain Personal Data from a Child under the Age of Consent longer than is reasonably necessary in order to fulfill the purpose for which it was disclosed.</p>
      <p>If you are a parent or guardian of a user of our Services who is a Child under the Age of Consent, you may contact us at any time to ask that (a) we stop collecting Personal Data from such user, (b) we assist you in deleting any Personal Data already collected from such user (although note that we may retain information in an anonymous, de-identified, or aggregated form where that information would not identify such user personally), or (c) we stop disclosing Personal Data collected from such user to third parties, but continue to allow for collection and use of Personal Data collected from such user in connection with the Services.&nbsp;</p>
      <p>If you are a Child under the Age of Consent, your parent or guardian can view all information within or associated with your Beem account, including, without limitation, all categories of Personal Data as outlined above, by contacting us at&nbsp;<a href="mailto:info@beem.earth" target="_blank" rel="noopener">info@beem.earth</a>.</p>
      <p>For clarity, we do not knowingly collect, maintain, use or share any Personal Data of any users that are a Child under the Age of Consent beyond that needed for authorized purposes, or as authorized by the user or his/her parent or legal guardian. In no instance do we sell any Personal Data of users that are a Child under the Age of Consent. We also do not knowingly use or disclose Personal Data of any users under the Age of Consent for behavioral targeting of advertisements.</p>
      <p><strong>6. California Resident Rights</strong></p>
      <p>This section provides additional disclosures required by the California Consumer Privacy Act (or &ldquo;CCPA&rdquo;). If you are a California resident, you have the rights set forth in this section. Please see the &ldquo;Exercising Your Rights&rdquo; section below for instructions regarding how to exercise these rights.&nbsp;</p>
      <p>If there are any conflicts between this section and any other provision of this Privacy Policy and you are a California resident, the portion that is more protective of Personal Data shall control to the extent of such conflict. If you have any questions about this section or whether any of the following rights apply to you, please contact us at&nbsp;<a href="mailto:info@beem.earth" target="_blank" rel="noopener">info@beem.earth.</a></p>
      <p><strong>Access</strong></p>
      <p>You have the right to request certain information about our collection and use of your Personal Data over the past 12 months. In response, we will provide you with the following information:</p>
      <ul>
        <li>The categories of Personal Data that we have collected about you.</li>
        <li>The categories of sources from which that Personal Data was collected.</li>
        <li>The business or commercial purpose for collecting or sharing your Personal Data.</li>
        <li>The categories of third parties with whom we have shared your Personal Data.</li>
        <li>The specific pieces of Personal Data that we have collected about you.</li>
      </ul>
      <p>If we have disclosed your Personal Data to any third parties for a business purpose over the past 12 months, we will identify the categories of Personal Data shared with each category of third party recipient. If we have shared your Personal Data over the past 12 months, we will identify the categories of Personal Data shared with each category of third party recipient.</p>
      <p><strong>Deletion</strong></p>
      <p>You have the right to delete or request that we assist in deleting the Personal Data that we have collected about you. Under the CCPA, this right is subject to certain exceptions: for example, we may need to retain your Personal Data to provide you with the Services or complete a transaction or other action you have requested. If your deletion request is subject to one of these exceptions, we may deny your deletion request.&nbsp;</p>
      <p><strong>Exercising Your Rights</strong></p>
      <p>To exercise the rights described above, you or your Authorized Agent (defined below) must send us a request that (1) provides sufficient information (e.g. login credentials to your account) to allow us to verify that you are the person about whom we have collected Personal Data, and (2) describes your request in sufficient detail to allow us to understand, evaluate and respond to it. Each request that meets both of these criteria will be considered a &ldquo;Valid Request.&rdquo; We may not respond to requests that do not meet these criteria. We will only use Personal Data provided in a Valid Request to verify your identity and complete your request. You do not need an account to submit a Valid Request.</p>
      <p>We will work to respond to your Valid Request within 45 days of receipt. We will not charge you a fee for making a Valid Request unless your Valid Request(s) is excessive, repetitive or manifestly unfounded. If we determine that your Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing your request.</p>
      <p>You may submit a Valid Request by emailing us at:&nbsp;<a href="mailto:info@beem.earth" target="_blank" rel="noopener">info@beem.earth</a>&nbsp;with the subject line &ldquo;CCPA Data Rights Request.&rdquo;</p>
      <p>You may also authorize an agent (an &ldquo;Authorized Agent&rdquo;) to exercise your rights on your behalf. To do this, you must provide your Authorized Agent with written permission to exercise your rights on your behalf, and we may request a copy of this written permission from your Authorized Agent when they make a request on your behalf.</p>
      <p><strong>Personal Data Sharing Opt-Out and Opt-In</strong></p>
      <p>As described in our Cookie Policy, we have incorporated Cookies from certain third parties into our Services. These Cookies allow those third parties to receive information about your activity on our Services that is associated with your browser or device. Those third parties may use that data to serve you relevant ads on other websites you visit. Under the CCPA, sharing your data through third-party Cookies for online advertising may be considered a &ldquo;sale&rdquo; of information. Beem has taken steps aimed at ensuring third-party Cookies incorporated into the Services do not constitute a &ldquo;sale&rdquo; as defined by that law.&nbsp;</p>
      <p>You always have full control over whether these technologies work on your devices.&nbsp; You can disable them entirely via app settings.</p>
      <p>Under the CCPA, we may share the Personal Data of minors between the ages of 13 and 16 pursuant to the Cookie use described above.&nbsp;</p>
      <p><strong>We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA</strong></p>
      <p>We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our goods or services, charge you different prices or rates, or provide you a lower quality of goods and services if you exercise your rights under the CCPA.&nbsp;</p>
      <p><strong>7. Other State Law Privacy Rights</strong></p>
      <p><strong>California Resident Rights</strong></p>
      <p>Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties&rsquo; direct marketing purposes; in order to submit such a request, please contact us at&nbsp;<a href="mailto:info@beem.earth" target="_blank" rel="noopener">info@beem.earth</a>.&nbsp;</p>
      <p><strong>Nevada Resident Rights</strong></p>
      <p><strong>Please note that we do not sell, or have plans to sell, your Personal Data as sales are defined in Nevada Revised Statutes Chapter 603A.&nbsp;</strong>However, if you would like to submit a request to opt-out of future sales (if any), please contact us at&nbsp;<a href="mailto:info@beem.earth" target="_blank" rel="noopener">info@beem.earth</a>&nbsp;with the subject line &ldquo;Nevada Do Not Sell Request&rdquo; and providing us with your name and the email address associated with your account.&nbsp;</p>
      <p><strong>8. European Union Data Subject Rights</strong></p>
      <p><strong>EU Residents</strong></p>
      <p>If you are a resident of the European Union (&ldquo;EU&rdquo;), United Kingdom, Lichtenstein, Norway or Iceland, you may have additional rights under the EU General Data Protection Regulation and the version of the GDPR retained in UK law (together the &ldquo;GDPR&rdquo;) with respect to your Personal Data, as outlined below. &nbsp;</p>
      <p>For this section, we use the terms &ldquo;Personal Data&rdquo; and &ldquo;processing&rdquo; as they are defined in the GDPR, but &ldquo;Personal Data&rdquo; generally means information that can be used to individually identify a person, and &ldquo;processing&rdquo; generally covers actions that can be performed in connection with data such as collection, use, storage and disclosure. Beem will be the controller of your Personal Data processed in connection with the Services.</p>
      <p>If there are any conflicts between this section and any other provision of this Privacy Policy, the policy or portion that is more protective of Personal Data shall control to the extent of such conflict. If you have any questions about this section or whether any of the following applies to you, please contact us at&nbsp;<a href="mailto:info@beem.earth" target="_blank" rel="noopener">info@beem.earth</a>.&nbsp;</p>
      <p><strong>Personal Data We Collect</strong></p>
      <p>The&nbsp;<u>&ldquo;Categories of Personal Data We Collect&rdquo;</u>&nbsp;section above details the Personal Data that we collect.</p>
      <p><strong>Personal Data Use and Processing Grounds</strong></p>
      <p>The&nbsp;<u>&ldquo;Our Commercial or Business Purposes for Collecting Personal Data&rdquo;</u>&nbsp;section above explains how we use your Personal Data.</p>
      <p>We will only process your Personal Data if we have a lawful basis for doing so. Lawful bases for processing include consent, contractual necessity and our &ldquo;legitimate interests&rdquo; or the legitimate interest of others, as further described below.</p>
      <p><u>Contractual Necessity:</u>&nbsp;We process the following categories of Personal Data as a matter of &ldquo;contractual necessity&rdquo;, meaning that we need to process the data to perform under our Terms of Service with you, which enables us to provide you with the Services. When we process data due to contractual necessity, failure to provide such Personal Data will result in your inability to use some or all portions of the Services that require such data.&nbsp;</p>
      <ul>
        <li>Profile or Contact Data</li>
        <li>Private Sensitive Personal Data</li>
        <li>Other Identifying Information that You Voluntarily Choose to Provide&nbsp;</li>
      </ul>
      <p><u>Legitimate Interest:</u>&nbsp;We process the following categories of Personal Data when we believe it furthers a legitimate interest (as defined by the GDPR):&nbsp;</p>
      <ul>
        <li>App Analytics</li>
        <li>Device/IP Data</li>
        <li>Web Analytics</li>
        <li>Advertising Data</li>
        <li>Consumer Demographic Data&nbsp;</li>
        <li>Geolocation Data</li>
        <li>Cookie &amp; Website Analytics</li>
        <li>Health Data</li>
        <li>Contact Information for Enterprise Account Sales</li>
        <li>Other Identifying Information that You Voluntarily Choose to Provide&nbsp;</li>
        <li>We may also de-identify or anonymize Personal Data to further our legitimate interests.&nbsp;</li>
      </ul>
      <p>Examples of these legitimate interests include (as described in more detail above):</p>
      <ul>
        <li>Providing, customizing, and improving the Services.</li>
        <li>Marketing the Services.</li>
        <li>Corresponding with you.</li>
        <li>Meeting legal requirements and enforcing legal terms.</li>
      </ul>
      <p><u>Consent:</u>&nbsp;In some cases, we process Personal Data based on the consent you expressly grant to us at the time we collect such data. When we process Personal Data based on your consent, it will be expressly indicated to you at the point and time of collection.&nbsp;</p>
      <p><u>Other Processing Grounds:</u>&nbsp;From time to time we may also need to process Personal Data to comply with a legal obligation, if it is necessary to protect the vital interests of you or other data subjects, or if it is necessary for a task carried out in the public interest.</p>
      <p><strong>Sharing Personal Data</strong></p>
      <p>The&nbsp;<u>&ldquo;Data Sharing&rdquo;</u>&nbsp;section above details how we share your Personal Data with third parties.&nbsp;</p>
      <p><strong>EU Data Subject Rights</strong></p>
      <p>You have certain rights with respect to your Personal Data, including those set forth below. To submit a request related to these rights, please email us at&nbsp;<a href="mailto:info@beem.earth" target="_blank" rel="noopener">info@beem.earth</a>. Please note that in some circumstances, we may not be able to fully comply with your request, such as if it is frivolous or extremely impractical, if it jeopardizes the rights of others, or if it is not required by law, but in those circumstances, we will still respond to notify you of such a decision. In some cases, we may also need you to provide us with additional information, which may include Personal Data, if necessary to verify your identity and the nature of your request. &nbsp;</p>
      <ul>
        <li><strong>Access</strong>:&nbsp;You can request more information about the Personal Data we hold about you and request a copy of such Personal Data. You can also access certain of your Personal Data by logging on to your account.</li>
        <li><strong>Rectification</strong>:&nbsp;If you believe that any Personal Data we are holding about you is incorrect or incomplete, you can request that we correct or supplement such data. You can also correct some of this information directly by logging on to your account.</li>
        <li><strong>Erasure</strong>:&nbsp;You can request that we erase some or all of your Personal Data from our systems. &nbsp;</li>
        <li><strong>Withdrawal of Consent</strong>: If we are processing your Personal Data based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any time. Please note, however, that if you exercise this right, you may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of your Personal Data, if such use or disclosure is necessary to enable you to utilize some or all of our Services.&nbsp;</li>
        <li><strong>Portability</strong>:&nbsp;You can ask for a copy of your Personal Data in a machine-readable format. You can also request that we transmit the data to another controller where technically feasible.&nbsp;</li>
        <li><strong>Objection</strong>:&nbsp;You can contact us to let us know that you object to the further use or disclosure of your Personal Data for certain purposes, such as for direct marketing purposes.&nbsp;</li>
        <li><strong>Restriction of Processing</strong>:&nbsp;You can ask us to restrict further processing of your Personal Data.</li>
        <li><strong>Right to File Complaint</strong>:&nbsp;You have the right to lodge a complaint about Beem&rsquo;s practices with respect to your Personal Data with the supervisory authority of your country or EU Member State. A list of Supervisory Authorities is available here:&nbsp;<u>https://edpb.europa.eu/about-edpb/board/members_en</u>.</li>
      </ul>
      <p><strong>Transfers of Personal Data</strong></p>
      <p>Beem is based and the Services are hosted and operated in the United States (&ldquo;U.S.&rdquo;) through Beem and its service providers. If you do not reside in the U.S., laws in the U.S. may differ from the laws where you reside. By using the Services, you acknowledge that any Personal Data about you, regardless of whether provided by you or obtained from a third party, is being provided to Beem in the U.S. and will be hosted on U.S. servers, and you authorize Beem to transfer, store and process your information to and in the U.S., and possibly other countries.&nbsp;</p>
      <p><strong>9. Brazil Data Subject Rights</strong></p>
      <p>Article 18 of the General Personal Data Protection Law (&ldquo;LGPD&rdquo;) establishes the following rights for users located in Brazil:</p>
      <ul>
        <li>Confirmation of the existence of processing;</li>
        <li>Access to data;</li>
        <li>Correction of incomplete, inaccurate or outdated data;</li>
        <li>Anonymization, blocking or deletion of unnecessary, excessive or processed data in violation of the provisions of the LGPD;</li>
        <li>Portability of data to another service or product provider, upon express request, in accordance with Brazil local regulations, and subject to commercial and industrial secrecy;</li>
        <li>Deletion of personal data processed with the consent of the data subject, except in the cases provided for in Article 16 of the LGPD;</li>
        <li>Information on public and private entities with which the controller has shared data;</li>
        <li>Information about the possibility of not giving consent and about the consequences of such refusal;</li>
        <li>Revocation of consent, pursuant to Article 8(5) of the LGPD.</li>
      </ul>
      <p>You may submit a Valid Request (see&nbsp;<u>CCPA section above</u>&nbsp;for details) by emailing us at&nbsp;<a href="mailto:info@beem.earth" target="_blank" rel="noopener">info@beem.earth</a>&nbsp;with the subject line &ldquo;LGPD Data Rights Request.&rdquo; Please note that in some circumstances, we may not be able to fully comply with your request, such as if it is frivolous or extremely impractical, if it jeopardizes the rights of others, or if it is not required by law, but in those circumstances, we will still respond to notify you of such a decision.</p>
      <p><strong>10. Changes to this Privacy Policy</strong></p>
      <p>We&rsquo;re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time, but we will alert you to any material changes by placing a notice on the Beem website, by sending you an email and/or by some other means. Please note that if you&rsquo;ve opted not to receive legal notice emails from us (or you haven&rsquo;t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.</p>
      <p><strong>11. Contact Information</strong></p>
      <p>If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at <a href="mailto:info@beem.earth" target="_blank" rel="noopener">info@beem.earth</a></p>
    </div>
  )
}

export default PrivacyPolicy;